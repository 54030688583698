<template>
  <div class="relative w-screen h-screen">
    <transition name="fade">
      <p
        v-if="showWelcome"
        class="absolute inset-x-0 top-5 z-10 text-3xl text-white text-center font-light tracking-wider"
        style="text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3)"
      >
        Willkommen bei Jamaya - schön, dass Du da bist!
      </p>
    </transition>

    <div class="relative w-full h-screen">
      <img
        src="/hero.jpg"
        alt="Hero background"
        class="w-full h-full object-cover"
      />
      <div class="absolute inset-0 flex items-center justify-center">
        <div class="text-center text-white">
          <h1 class="text-5xl font-bold mb-4">The Reconnection Experience</h1>
          <p class="text-2xl">
            Deine ganzheitliche Reise zu nachhaltiger Veränderung
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";

const showWelcome = ref(false);

onMounted(() => {
  setTimeout(() => {
    showWelcome.value = true;

    setTimeout(() => {
      showWelcome.value = false;
    }, 5000);
  }, 1000);
});
</script>

<style scoped>
/* These transition classes are needed since Tailwind doesn't handle Vue transitions by default */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
