import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "relative w-screen h-screen" }
const _hoisted_2 = {
  key: 0,
  class: "absolute inset-x-0 top-5 z-10 text-3xl text-white text-center font-light tracking-wider",
  style: {"text-shadow":"2px 2px 4px rgba(0, 0, 0, 0.3)"}
}

import { ref, onMounted } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'HomeView',
  setup(__props) {

const showWelcome = ref(false);

onMounted(() => {
  setTimeout(() => {
    showWelcome.value = true;

    setTimeout(() => {
      showWelcome.value = false;
    }, 5000);
  }, 1000);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (showWelcome.value)
          ? (_openBlock(), _createElementBlock("p", _hoisted_2, " Willkommen bei Jamaya - schön, dass Du da bist! "))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _cache[0] || (_cache[0] = _createStaticVNode("<div class=\"relative w-full h-screen\" data-v-5c3df2ee><img src=\"/hero.jpg\" alt=\"Hero background\" class=\"w-full h-full object-cover\" data-v-5c3df2ee><div class=\"absolute inset-0 flex items-center justify-center\" data-v-5c3df2ee><div class=\"text-center text-white\" data-v-5c3df2ee><h1 class=\"text-5xl font-bold mb-4\" data-v-5c3df2ee>The Reconnection Experience</h1><p class=\"text-2xl\" data-v-5c3df2ee> Deine ganzheitliche Reise zu nachhaltiger Veränderung </p></div></div></div>", 1))
  ]))
}
}

})